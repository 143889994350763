import request from '@/plugins/axios'
// 资产列表
export const loadMusicData = (params: { page_size: number; offset: number; order?: string }): any =>
  request({
    url: `/v2/digital/list`,
    method: 'get',
    params,
  })

// 资产估值
export const loadDigitalData = (): any =>
  request({
    url: `/v2/digital/value`,
    method: 'get',
  })
